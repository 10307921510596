import React, { useEffect, useState } from "react";
import api from "../Services/api";

const Main = () => {
  const [id, setId] = useState(null);

  const fetchDataWithCookie = async () => {
    try {
      const response = await api.get(
        `${process.env.REACT_APP_API_URL}/api/owner/token`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true, // 쿠키를 요청에 포함
        }
      );
      console.log(response.data);
      setId(response.data);
    } catch (error) {
      // 에러 처리
      console.error("API 요청 중 에러 발생:", error);
      throw error; // 필요시 호출한 쪽에서 에러를 처리하도록 던짐
    }
  };

  useEffect(() => {
    fetchDataWithCookie();
  }, []);

  // 마이페이지로 이동하는 임시 함수
  const goToMyPage = () => {
    window.location.href = `/owner-dash/${id}?newOwner=true`;
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <img src="/smoLogo.png" alt="SMO Logo" className="w-50 h-[74.7px] mb-6" />
      <h1 className="text-4xl font-bold text-gray-800 mb-4">
        <span className="text-emerald-500">SMO</span>에 오신 것을 환영합니다!
      </h1>
      <p className="text-lg text-gray-600 mb-8">
        손쉬운 업체관리 서비스를 이용해보세요.
      </p>

      {/* 마이페이지로 이동하는 버튼 */}
      <button
        onClick={goToMyPage}
        className="px-6 py-3 bg-emerald-500 text-white rounded-md hover:bg-emerald-600 transition duration-300"
      >
        마이페이지 바로가기
      </button>
    </div>
  );
};

export default Main;
