import axios, { AxiosRequestConfig } from 'axios';


// 환경 변수에서 기본 URL 가져오기
const baseURL = process.env.REACT_APP_TABLE_ORDER_SPRING_BASE_URL;

// Axios 인스턴스 생성
const api = axios.create({
  baseURL: baseURL || 'https://springbase-dev3.dev.ok114.kr',
  // headers: {
  //   'Content-Type': 'application/json',
  // },
});

const redirectToOwnerLogin = () => {
  const currentURL = encodeURIComponent(window.location.href);
  // 로그인 페이지로 리다이렉트 (리다이렉트 URL과 토큰 이름을 쿼리 파라미터로 보냄)
  const loginURL = `https://dev-auth.table-order.ok114.kr/login?redirect=${currentURL}`;

  window.location.replace(loginURL);
};

const redirectToAdminLogin = (loginUrl) => {
  window.location.replace(loginUrl);
};

api.interceptors.request.use(
  (config) => {
    if (!config.url) {
      return config;  // url이 없는 경우 그대로 요청 진행
    }

    let relativePath = config.url;
    if (baseURL && config.url.startsWith(baseURL)) {
      relativePath = config.url.replace(baseURL, ""); // baseURL 제거
    }

    relativePath = relativePath.replace("/api", "");// /api를 제거

    console.log("추출된 경로: ", relativePath); // 경로 로그 확인

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 인터셉터 설정
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {

      if (error.response.status === 401) {
        if (error.response.data.loginUrl) {
          redirectToAdminLogin(error.response.data.loginUrl);
        } else {
          redirectToOwnerLogin();
        }

      } else if (error.response.status === 403) {
        window.location.replace('/no-access');
      } else if (error.response.status === 404) {
        if (error.response.data.signupUrl) {
          const signupUrl = error.response.data.signupUrl;
          window.location.replace(`${process.env.REACT_APP_REACT_ADMIN}${signupUrl}`);
        }
      } else {
        console.log(JSON.stringify(error.response, null, 2));

      }
    }

    return Promise.reject(error);
  }
);

export default api;
