import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import { DashboardCard, AlertItem } from '../components/CommonComponents';

const HomePage = () => {
  const [timeRange, setTimeRange] = useState('일간');
  
  const salesData = [
    { name: '월', 식사: 4000, 음료: 2400, 디저트: 2400 },
    { name: '화', 식사: 3000, 음료: 1398, 디저트: 2210 },
    { name: '수', 식사: 2000, 음료: 9800, 디저트: 2290 },
    { name: '목', 식사: 2780, 음료: 3908, 디저트: 2000 },
    { name: '금', 식사: 1890, 음료: 4800, 디저트: 2181 },
    { name: '토', 식사: 2390, 음료: 3800, 디저트: 2500 },
    { name: '일', 식사: 3490, 음료: 4300, 디저트: 2100 },
  ];

  const productData = [
    { name: '스테이크', 판매량: 4000 },
    { name: '파스타', 판매량: 3000 },
    { name: '샐러드', 판매량: 2000 },
    { name: '피자', 판매량: 2780 },
    { name: '와인', 판매량: 1890 },
  ];

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-4 gap-4">
        <DashboardCard title="오늘의 매출" value="₩1,234,567" change="+5.2%" />
        <DashboardCard title="주문 수" value="89" change="+3.7%" />
        <DashboardCard title="평균 주문 가치" value="₩56,789" change="-1.2%" />
        <DashboardCard title="활성 테이블" value="12/20" change="+2" />
      </div>
      <div className="bg-gray-800 p-4 rounded-lg">
        <h3 className="text-xl font-bold mb-4">매출 추이 ({timeRange})</h3>
        <div className="flex justify-end space-x-2 mb-4">
          {['일간', '주간', '월간'].map((range) => (
            <button
              key={range}
              className={`px-3 py-1 rounded ${timeRange === range ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300'}`}
              onClick={() => setTimeRange(range)}
            >
              {range}
            </button>
          ))}
        </div>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={salesData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="식사" stroke="#8884d8" />
            <Line type="monotone" dataKey="음료" stroke="#82ca9d" />
            <Line type="monotone" dataKey="디저트" stroke="#ffc658" />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="bg-gray-800 p-4 rounded-lg">
          <h3 className="text-xl font-bold mb-4">인기 상품</h3>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={productData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="판매량" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="bg-gray-800 p-4 rounded-lg">
          <h3 className="text-xl font-bold mb-4">최근 알림</h3>
          <div className="space-y-2">
            <AlertItem message="와인 재고가 20% 미만입니다." />
            <AlertItem message="주방 직원 2명이 오늘 병가를 냈습니다." />
            <AlertItem message="점심 시간대 매출이 전주 대비 15% 증가했습니다." />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;