import React from 'react';
import { Edit, Trash2 } from 'lucide-react';

const EmployeeManagementPage = () => {
  const employees = [
    { id: 1, name: "김철수", position: "매니저", department: "홀", status: "재직" },
    { id: 2, name: "이영희", position: "주방장", department: "주방", status: "재직" },
    { id: 3, name: "박지성", position: "서버", department: "홀", status: "휴직" },
  ];

  return (
    <div className="space-y-6">
     {/* 필터링 바 */}
     <div className="bg-gray-700 p-2 rounded-lg flex items-center space-x-4">
        {/* 셀렉트 박스 */}
        <select className="px-4 py-2 bg-gray-800 text-gray-300 rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500">
          <option value="">업체 선택</option>
          <option value="">업체1</option>
          <option value="">업체2</option>
          <option value="">업체3</option>
          <option value="">업체4</option>
          <option value="">업체5</option>
        </select>

        <select className="px-4 py-2 bg-gray-800 text-gray-300 rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500">
          <option value="">직책 선택</option>
          <option value="">직책1</option>
          <option value="">직책2</option>
          <option value="">직책3</option>
          <option value="">직책4</option>
          <option value="">직책5</option>
        </select>

        <select className="px-4 py-2 bg-gray-800 text-gray-300 rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500">
          <option value="">상태 선택</option>
          <option value="">상태1</option>
          <option value="">상태2</option>
        </select>

        {/* 검색 입력 필드 */}
        <input
          type="text"
          placeholder="직원 검색"
          className="px-4 py-2 bg-gray-800 text-gray-300 rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />

        {/* 검색 버튼 */}
        <button className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 focus:outline-none">
          검색
        </button>
      </div>

  
      <div className="bg-gray-800 rounded-lg overflow-hidden">
        <table className="min-w-full divide-y divide-gray-700">
          <thead className="bg-gray-700">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">이름</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">직책</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">부서</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">상태</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">관리</th>
            </tr>
          </thead>
          <tbody className="bg-gray-800 divide-y divide-gray-700">
            {employees.map((employee) => (
              <tr key={employee.id}>
                <td className="px-6 py-4 whitespace-nowrap">{employee.name}</td>
                <td className="px-6 py-4 whitespace-nowrap">{employee.position}</td>
                <td className="px-6 py-4 whitespace-nowrap">{employee.department}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    employee.status === '재직' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                  }`}>
                    {employee.status}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button className="text-indigo-600 hover:text-indigo-900 mr-4">
                    <Edit size={18} />
                  </button>
                  <button className="text-red-600 hover:text-red-900">
                    <Trash2 size={18} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmployeeManagementPage;