import React, { useEffect, useState } from "react";
import { Plus, MapPin, Phone, Clock } from "lucide-react";
import { StoreRegistrationForm } from "../components/StoreRegistrationForm";
import api from "../Services/api";
import { PendingStoreUpdate } from "../components/PendingStoreUpdate";
import CustomAlert from "../components/CustomAlert";

const StoreCard = ({ store, isPending, onClick }) => {
  return (
    <div
      className={`bg-gradient-to-b ${
        isPending
          ? "from-yellow-800 to-yellow-900"
          : "from-gray-800 to-gray-900"
      } p-6 rounded-lg shadow-lg h-96 flex flex-col justify-between transform hover:scale-105 transition-all duration-300 cursor-pointer`}
      onClick={onClick}
    >
      <div>
        <div className="flex justify-between items-center mb-4">
          {/* 왼쪽에 이름 배치 */}
          <h3 className="text-2xl font-bold text-blue-400">{store.name}</h3>

          {/* 오른쪽에 review 여부 배치 */}
          {isPending && (
            <p
              className={`font-semibold ${
                store.reviewed ? "text-green-500" : "text-yellow-500"
              }`}
            >
              {store.reviewed ? "검토 완료" : "검토 대기"}
            </p>
          )}
        </div>
        <div className="space-y-2 mb-4 text-gray-300">
          <p className="flex items-center">
            <MapPin size={16} className="mr-2 text-yellow-500" />
            {store.address}
          </p>
          <p className="flex items-center">
            <Phone size={16} className="mr-2 text-green-500" />
            {store.contactNumber}
          </p>
          {!isPending && (
            <p className="flex items-center">
              <Clock size={16} className="mr-2 text-purple-500" />
              {`${store.businessStartHours} - ${store.businessEndHours}`}
            </p>
          )}

          {isPending && (
            <div className="mt-4 space-y-4">
              <div>
                {/* storeStatus description과 comment를 묶어서 표시 */}
                <div className="bg-yellow-700 p-4 rounded-lg">
                <p className="text-lg text-red-700 font-extrabold">
                    {store.storeStatus?.description}
                  </p>
                  {store.comment && (
                    <p className="text-sm text-gray-300 mt-2"><p className="text-base font-bold text-gray-300">상세 사유  </p>{store.comment}</p>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mt-auto">
        <span
          className={`px-4 py-2 rounded-full text-sm font-semibold ${
            isPending
              ? "bg-yellow-500 text-gray-900"
              : store.isActive
              ? "bg-green-500 text-white"
              : "bg-red-500 text-white"
          }`}
        >
          {isPending ? "승인 대기 중" : store.isActive ? "영업중" : "영업 중지"}
        </span>
      </div>
    </div>
  );
};

const AddStoreCard = ({ onAddStore }) => {
  return (
    <div
      className="bg-gradient-to-b from-blue-700 to-blue-900 p-6 rounded-lg shadow-lg h-96 flex flex-col items-center justify-center transform hover:scale-105 transition-all duration-300 cursor-pointer"
      onClick={onAddStore}
    >
      <Plus size={64} className="text-blue-300 mb-4 animate-pulse" />
      <p className="text-blue-300 text-2xl font-bold mb-4">새 매장 추가</p>
      <button className="bg-blue-500 text-white px-6 py-3 rounded-full hover:bg-blue-600 transition duration-300 transform hover:scale-110">
        매장 등록하기
      </button>
    </div>
  );
};

const StoreManagementPage = () => {
  const [pendingStores, setPendingStores] = useState([]);
  const [activeStores, setActiveStores] = useState([]);
  const [isRegistering, setIsRegistering] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [editingStoreId, setEditingStoreId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [customAlert, setCustomAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");


  useEffect(() => {
    fetchStores();
  }, []);

  const fetchStores = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await api.get(
        `${process.env.REACT_APP_API_URL}/api/owner/stores`,
        {
          withCredentials: true,
        }
      );

      console.log(response.data);
      setPendingStores(response.data.pendingStoreList);
      setActiveStores(response.data.storeList);
    } catch (err) {
      setError("매장 목록을 불러오는 중 오류가 발생했습니다.");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const closeAlert = () => {
    setCustomAlert(false);
  };

  const handleEditStore = (storeId, isPending) => {
    if (isPending) {
      // 해당 storeId에 해당하는 pendingStore를 찾음
      const pendingStore = pendingStores.find((store) => store.id === storeId);

      // pendingStore가 존재하고 reviewed가 true일 때만 실행
      if (pendingStore && pendingStore.reviewed === true) {
        setIsUpdating(true);
        setEditingStoreId(storeId); // 수정할 storeId 설정
      } else {
        setAlertMessage("⚠️<strong> 관리자 검토 진행중 </strong><br><br>검토 완료 후 수정 가능합니다.");
        setCustomAlert(true);
        
        return;
      }
    }
  };

  const handleAddStore = () => {
    setIsRegistering(true);
  };

  const handleCloseRegistration = () => {
    setIsRegistering(false);
    setIsUpdating(false);
  };

  const handleStoreAdded = (newStore) => {
    setPendingStores((prevStores) => [...prevStores, newStore]);
    setIsRegistering(false);
  };

  const handleStoreUpdated = (updatedStore) => {
    setPendingStores((prevStores) =>
      prevStores.map((store) =>
        store.id === updatedStore.id ? updatedStore : store
      )
    );
    setIsUpdating(false);
  };

  if (isLoading) {
    return <div className="text-white text-center py-10">로딩 중...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center py-10">{error}</div>;
  }

  return (
    <div className="p-6 bg-gray-900 min-h-screen">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
        {activeStores.map((store) => (
          <StoreCard
            key={store.id}
            store={store}
            isPending={false}
            onClick={() => handleEditStore(store.id, false)}
          />
        ))}
        <AddStoreCard onAddStore={handleAddStore} />
      </div>

      <h2 className="text-2xl font-bold text-white mb-6">
        승인 대기 중인 매장
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 mb-12">
        {pendingStores.map((store) => (
          <StoreCard
            key={store.id}
            store={store}
            isPending={true}
            onClick={() => handleEditStore(store.id, true)}
          />
        ))}
      </div>

      {isRegistering && (
        <StoreRegistrationForm
          onClose={handleCloseRegistration}
          onStoreAdded={handleStoreAdded}
        />
      )}

      {isUpdating && (
        <PendingStoreUpdate
          onClose={handleCloseRegistration}
          onStoreUpdated={handleStoreUpdated}
          storeId={editingStoreId}
        />
      )}

      {customAlert && (
        <CustomAlert
          message={alertMessage}
          onClose={closeAlert}
        />
      )}
    </div>
  );
};

export default StoreManagementPage;
