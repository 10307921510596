import React, { useState, useEffect } from "react";
import {
  MapPin,
  Phone,
  CreditCard,
  FileText,
  Briefcase,
  ChevronRight,
  ChevronLeft,
  Check,
  X,
  AlertCircle,
  Loader,
  Image,
} from "lucide-react";
import axios from "axios";
import api from "../Services/api";

const steps = [
  { id: "basicInfo", title: "기본 정보", icon: MapPin },
  { id: "idCard", title: "신분증", icon: CreditCard },
  { id: "bankStatement", title: "통장 사본", icon: FileText },
  { id: "businessLicense", title: "사업자 등록증", icon: Briefcase },
];

const Tooltip = ({ children, content }) => (
  <div className="group relative">
    {children}
    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-1 bg-gray-700 text-white text-xs rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap">
      {content}
      <div className="absolute top-full left-1/2 transform -translate-x-1/2 border-4 border-transparent border-t-gray-700"></div>
    </div>
  </div>
);

export const PendingStoreUpdate = ({ onClose, onStoreUpdated, storeId }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [idCardFile, setIdCardFile] = useState(null);
  const [bankStatementFile, setBankStatementFile] = useState(null);
  const [businessLicenseFile, setBusinessLicenseFile] = useState(null);

  const [idCardPreview, setIdCardPreview] = useState(null);
  const [bankStatementPreview, setBankStatementPreview] = useState(null);
  const [businessLicensePreview, setBusinessLicensePreview] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [animation, setAnimation] = useState("");

  const [pendingStore, setPendingStore] = useState();

  useEffect(() => {
    if (storeId) {
      loadStoreData(storeId);
    }
  }, [storeId]);

  const loadStoreData = async (storeId) => {
    try {
      setIsLoading(true);

      // 1. 매장 기본 정보 불러오기
      const storeResponse = await api.get(
        `${process.env.REACT_APP_API_URL}/api/owner/pending-store/${storeId}`,
        {
          withCredentials: true,
        }
      );
      const store = storeResponse.data;
      setPendingStore(store);

      // 2. 문서 정보 불러오기
      const documentsResponse = await api.get(
        `${process.env.REACT_APP_API_URL}/api/owner/pending-store/${storeId}/document`,
        {
          withCredentials: true,
        }
      );
      const documents = documentsResponse.data;

      // 3. 문서 필터링: documentType을 기준으로 파일을 구분
      const idCardFile =
        documents.find((doc) => doc.documentType === "IDCard") || null;
      const bankStatementFile =
        documents.find((doc) => doc.documentType === "BankStatement") || null;
      const businessLicenseFile =
        documents.find((doc) => doc.documentType === "BusinessLicense") || null;

      // 4. 기존 데이터를 폼에 세팅
      setName(store.name);
      setAddress(store.address);
      setContactNumber(store.contactNumber);

      if (idCardFile) {
        const fileUrl = `${process.env.REACT_APP_API_URL}${idCardFile.fileUrl}`;
        setIdCardFile(fileUrl);
        setIdCardPreview(fileUrl);
      }

      if (bankStatementFile) {
        const fileUrl = `${process.env.REACT_APP_API_URL}${bankStatementFile.fileUrl}`;
        setBankStatementFile(fileUrl);
        setBankStatementPreview(fileUrl);
      }

      if (businessLicenseFile) {
        const fileUrl = `${process.env.REACT_APP_API_URL}${businessLicenseFile.fileUrl}`;
        setBusinessLicenseFile(fileUrl);
        setBusinessLicensePreview(fileUrl);
      }
    } catch (err) {
      setError("매장 정보를 불러오는 중 오류가 발생했습니다.");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setAnimation("fade-in");
    const timer = setTimeout(() => setAnimation(""), 500);
    return () => clearTimeout(timer);
  }, [currentStep]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "name":
        setName(value);
        break;
      case "address":
        setAddress(value);
        break;
      case "contactNumber":
        setContactNumber(value);
        break;
      default:
        break;
    }
  };

  const handleFileChange = (e) => {
    const { name, files: selectedFiles } = e.target;
    console.log("name:", name); // name이 제대로 전달되는지 확인
    console.log("files:", selectedFiles); // 파일 리스트가 제대로 전달되는지 확인

    if (selectedFiles && selectedFiles[0]) {
      const file = selectedFiles[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        console.log(reader.result);

        switch (name) {
          case "idCardFile":
            setIdCardFile(file);
            setIdCardPreview(reader.result);
            break;
          case "bankStatementFile":
            setBankStatementFile(file);
            setBankStatementPreview(reader.result);
            break;
          case "businessLicenseFile":
            setBusinessLicenseFile(file);
            setBusinessLicensePreview(reader.result);
            break;
          default:
            break;
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const getStepSummary = (index) => {
    switch (index) {
      case 0:
        return name
          ? `${name.substring(0, 15)}${name.length > 15 ? "..." : ""}`
          : "미입력";
      case 1:
        return idCardFile ? "파일 선택됨" : "미선택";
      case 2:
        return bankStatementFile ? "파일 선택됨" : "미선택";
      case 3:
        return businessLicenseFile ? "파일 선택됨" : "미선택";
      default:
        return "";
    }
  };

  const validateStep = () => {
    switch (currentStep) {
      case 0:
        return name && address && contactNumber;
      case 1:
        return idCardFile;
      case 2:
        return bankStatementFile;
      case 3:
        return businessLicenseFile;
      default:
        return false;
    }
  };

  const handleNext = () => {
    if (validateStep()) {
      setAnimation("slide-out-left");
      setTimeout(() => {
        setCurrentStep((prevStep) => prevStep + 1);
        setAnimation("slide-in-right");
      }, 300);
    } else {
      setError("모든 필드를 채워주세요.");
    }
  };

  const handlePrevious = () => {
    setAnimation("slide-out-right");
    setTimeout(() => {
      setCurrentStep((prevStep) => prevStep - 1);
      setAnimation("slide-in-left");
    }, 300);
    setError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateStep()) {
      setError("모든 필드를 채워주세요.");
      return;
    }

    setIsLoading(true);
    setError(null);
    setSuccess(false);

    const formDataToSend = new FormData();
    formDataToSend.append("name", name);
    formDataToSend.append("address", address);
    formDataToSend.append("contactNumber", contactNumber);

    if (typeof idCardFile === "string") {
      formDataToSend.append("idCardFilePath", idCardFile);
    } else {
      formDataToSend.append("idCardFile", idCardFile);
    }

    if (typeof bankStatementFile === "string") {
      formDataToSend.append("bankStatementFilePath", bankStatementFile);
    } else {
      formDataToSend.append("bankStatementFile", bankStatementFile);
    }

    if (typeof businessLicenseFile === "string") {
      formDataToSend.append("businessLicenseFilePath", businessLicenseFile);
    } else {
      formDataToSend.append("businessLicenseFile", businessLicenseFile);
    }

    formDataToSend.forEach((value, key) => {
      console.log(`${key}: ${value}`);
    });

    try {
      let response = await api.put(
        `${process.env.REACT_APP_API_URL}/api/owner/pending/store/${storeId}`, // PUT 요청 (수정)
        formDataToSend,
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setSuccess(true);
        onStoreUpdated(response.data); // 성공 시 추가/수정 데이터 처리
        setTimeout(() => {
          onClose();
        }, 1000);
      }
    } catch (err) {
      setError(
        err.response?.data?.message || "업체 등록 중 오류가 발생했습니다."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-300 mb-2"
              >
                업체 이름
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="address"
                className="block text-sm font-medium text-gray-300 mb-2"
              >
                <MapPin size={16} className="inline mr-2" />
                업체 주소
              </label>
              <input
                type="text"
                id="address"
                name="address"
                value={address}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="contactNumber"
                className="block text-sm font-medium text-gray-300 mb-2"
              >
                <Phone size={16} className="inline mr-2" />
                연락처 번호
              </label>
              <input
                type="tel"
                id="contactNumber"
                name="contactNumber"
                value={contactNumber}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </>
        );
      case 1:
        return (
          <div className="mb-4">
            <label
              htmlFor="idCardFile"
              className="block text-sm font-medium text-gray-300 mb-2"
            >
              <CreditCard size={16} className="inline mr-2" />
              신분증 파일
            </label>
            <input
              type="file"
              id="idCardFile"
              name="idCardFile"
              onChange={handleFileChange}
              accept="image/*"
              className="hidden"
            />
            <div
              className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md cursor-pointer"
              onClick={() => document.getElementById("idCardFile").click()}
            >
              <div className="space-y-1 text-center">
                {idCardPreview ? (
                  <img
                    src={idCardPreview}
                    alt="ID Card Preview"
                    className="mx-auto h-32 w-auto"
                  />
                ) : (
                  <Image
                    className="mx-auto h-12 w-12 text-gray-400"
                    aria-hidden="true"
                  />
                )}
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor="idCardFile"
                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                  >
                    <span>파일 업로드</span>
                  </label>
                  <p className="pl-1">또는 드래그 앤 드롭</p>
                </div>
                <p className="text-xs text-gray-500">
                  PNG, JPG, GIF up to 10MB
                </p>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="mb-4">
            <label
              htmlFor="bankStatementFile"
              className="block text-sm font-medium text-gray-300 mb-2"
            >
              <FileText size={16} className="inline mr-2" />
              통장 사본 파일
            </label>
            <input
              type="file"
              id="bankStatementFile"
              name="bankStatementFile"
              onChange={handleFileChange}
              accept="image/*,.pdf"
              className="hidden"
            />
            <div
              className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md cursor-pointer"
              onClick={() =>
                document.getElementById("bankStatementFile").click()
              }
            >
              <div className="space-y-1 text-center">
                {bankStatementPreview ? (
                  <img
                    src={bankStatementPreview}
                    alt="Bank Statement Preview"
                    className="mx-auto h-32 w-auto"
                  />
                ) : (
                  <FileText
                    className="mx-auto h-12 w-12 text-gray-400"
                    aria-hidden="true"
                  />
                )}
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor="bankStatementFile"
                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                  >
                    <span>파일 업로드</span>
                  </label>
                  <p className="pl-1">또는 드래그 앤 드롭</p>
                </div>
                <p className="text-xs text-gray-500">
                  PNG, JPG, GIF, PDF up to 10MB
                </p>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="mb-4">
            <label
              htmlFor="businessLicenseFile"
              className="block text-sm font-medium text-gray-300 mb-2"
            >
              <Briefcase size={16} className="inline mr-2" />
              사업자 등록증 파일
            </label>
            <input
              type="file"
              id="businessLicenseFile"
              name="businessLicenseFile"
              onChange={handleFileChange}
              accept="image/*,.pdf"
              className="hidden"
            />
            <div
              className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md cursor-pointer"
              onClick={() =>
                document.getElementById("businessLicenseFile").click()
              }
            >
              <div className="space-y-1 text-center">
                {businessLicensePreview ? (
                  <img
                    src={businessLicensePreview}
                    alt="Business License Preview"
                    className="mx-auto h-32 w-auto"
                  />
                ) : (
                  <Briefcase
                    className="mx-auto h-12 w-12 text-gray-400"
                    aria-hidden="true"
                  />
                )}

                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor="businessLicenseFile"
                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                  >
                    <span>파일 업로드</span>
                  </label>
                  <p className="pl-1">또는 드래그 앤 드롭</p>
                </div>
                <p className="text-xs text-gray-500">
                  PNG, JPG, GIF, PDF up to 10MB
                </p>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-2xl font-bold text-white">매장 정보 수정</h2>
          <button
            onClick={onClose}
            className="text-white hover:text-red-500 transition duration-300"
          >
            <X size={24} />
          </button>
        </div>
        {pendingStore?.storeStatus?.description && (
            <p className="text-red-500 mb-4">
              {pendingStore.storeStatus.description}
            </p>
          )}

        {error && (
          <div className="mb-4 p-3 bg-red-500 bg-opacity-20 border border-red-500 rounded-md flex items-center">
            <AlertCircle size={20} className="text-red-500 mr-2" />
            <p className="text-red-500">{error}</p>
          </div>
        )}
        {success && (
          <div className="mb-4 p-3 bg-green-500 bg-opacity-20 border border-green-500 rounded-md flex items-center">
            <Check size={20} className="text-green-500 mr-2" />
            <p className="text-green-500">매장이 성공적으로 등록되었습니다.</p>
          </div>
        )}
        <div className="mb-8 relative">
          <div className="absolute top-[35%] left-4 w-[90%] h-1 bg-gray-600 transform -translate-y-1/2">
            <div
              className="h-full bg-blue-500 transition-all duration-300 ease-in-out"
              style={{ width: `${(currentStep / (steps.length - 1)) * 100}%` }}
            ></div>
          </div>
          <div className="flex justify-between items-center relative">
            {steps.map((step, index) => (
              <Tooltip key={step.id} content={getStepSummary(index)}>
                <div className="flex flex-col items-center z-10">
                  <div
                    className={`w-12 h-12 rounded-full flex items-center justify-center transition-colors duration-300 ${
                      index < currentStep
                        ? "bg-green-500"
                        : index === currentStep
                        ? "bg-blue-500"
                        : "bg-gray-600"
                    }`}
                  >
                    {index < currentStep ? (
                      <Check size={24} className="text-white" />
                    ) : (
                      <step.icon size={24} className="text-white" />
                    )}
                  </div>
                  <p className="text-xs mt-2 text-gray-400">{step.title}</p>
                </div>
              </Tooltip>
            ))}
          </div>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className={`transition-all duration-300 ${animation}`}>
            {renderStepContent()}
          </div>
          <div className="flex justify-between space-x-2 mt-6">
            {currentStep > 0 && (
              <button
                type="button"
                onClick={handlePrevious}
                className="px-4 py-2 bg-gray-600 text-white rounded-full hover:bg-gray-700 transition duration-300 flex items-center"
              >
                <ChevronLeft size={20} className="mr-1" />
                이전
              </button>
            )}
            {currentStep < steps.length - 1 ? (
              <button
                type="button"
                onClick={handleNext}
                className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition duration-300 flex items-center ml-auto"
              >
                다음
                <ChevronRight size={20} className="ml-1" />
              </button>
            ) : (
              <button
                type="submit"
                disabled={isLoading}
                className="px-4 py-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition duration-300 flex items-center ml-auto disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isLoading ? (
                  <>
                    <Loader size={20} className="mr-2 animate-spin" />
                    등록 중...
                  </>
                ) : (
                  <>
                    매장 등록
                    <Check size={20} className="ml-2" />
                  </>
                )}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
