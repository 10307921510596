import {
  Home,
  LogOut,
  Settings,
  Store,
  Users
} from "lucide-react";
import React, { useEffect, useState } from "react";
import EmployeeManagementPage from "../pages/EmployeeManagementPage";
import HomePage from "../pages/HomePage";
import SettingsPage from "../pages/SettingsPage";
import StoreManagementPage from "../pages/StoreManagementPage";
import { useLocation, useNavigate } from 'react-router-dom';
import api from "../Services/api";

const AdminDashboard = () => {
  const [selectedMenu, setSelectedMenu] = useState("홈");
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const userInfo = async () => {
      try {
          // API 요청 시작 시 로딩 상태 설정
          setLoading(true);
          const response = await api.get(
              `${process.env.REACT_APP_API_URL}/api/owner/token`,
              {
                  withCredentials: true
              }
          );
          console.log(response.data);
          setLoading(false);
      } catch (error) {
          setLoading(false);
      }
  };

  useEffect(() => {
      userInfo();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const isNew = searchParams.get("newOwner");
    if (isNew) {
      setSelectedMenu("매장 관리");

      navigate(location.pathname, { replace: true });
    }
  }, [location]);

  const handleLogout = async () => {
    console.log("logout");
  };

  const menuItems = [
    { icon: <Home size={18} />, text: "홈" },
    { icon: <Store size={18} />, text: "매장 관리" },
    // { icon: <BarChart2 size={18} />, text: "매출 분석" },
    { icon: <Users size={18} />, text: "직원 관리" },
    { icon: <Settings size={18} />, text: "설정" },
  ];

  const renderContent = () => {
    switch (selectedMenu) {
      case "홈":
        return <HomePage />;
      case "매장 관리":
        return <StoreManagementPage />;
      // case "매출 분석":
      //   return <SalesAnalysisPage />;
      case "직원 관리":
        return <EmployeeManagementPage />;
      case "설정":
        return <SettingsPage />;
      default:
        return <h2 className="text-2xl font-bold">선택된 메뉴 없음</h2>;
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900 text-white">
        로딩 중...
      </div>
    );
  }

  // if (!user) {
  //   return (
  //     <div className="flex justify-center items-center h-screen bg-gray-900 text-white">
  //       <div className="text-center">
  //         <h2 className="text-2xl font-bold mb-4">로그인이 필요합니다</h2>
  //         <button
  //           onClick={handleLogin}
  //           className="bg-blue-600 text-white px-4 py-2 rounded-full"
  //         >
  //           로그인
  //         </button>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className="flex h-screen bg-gray-900 text-white">
      {/* 사이드바 */}
      <div className="w-64 bg-gray-800">
        <div className="flex items-center justify-center h-16 border-b border-gray-700">
          <h1 className="text-xl font-bold">테이블 오더 관리</h1>
        </div>
        <nav className="mt-4">
          <ul>
            {menuItems.map((item, index) => (
              <li key={index}>
                <button
                  className={`flex items-center w-full px-4 py-2 text-gray-300 hover:bg-gray-700 ${
                    selectedMenu === item.text ? "bg-gray-700" : ""
                  }`}
                  onClick={() => setSelectedMenu(item.text)}
                >
                  {item.icon}
                  <span className="ml-2">{item.text}</span>
                </button>
              </li>
            ))}
          </ul>
        </nav>
        <div className="absolute bottom-0 w-64 p-4">
          <div className="flex items-center justify-between text-sm">
            {/* <span>{user.email}</span> */}
            <button
              onClick={handleLogout}
              className="text-gray-400 hover:text-white"
            >
              <LogOut size={18} />
            </button>
          </div>
        </div>
      </div>

      {/* 메인 콘텐츠 */}
      <div className="flex-1 overflow-auto">
        {/* <header className="bg-gray-800 shadow-sm">
          <div className="flex items-center justify-between px-8 py-4">
            <h2 className="text-2xl font-bold">{selectedMenu}</h2>
            <div className="flex items-center space-x-4">
              <div className="relative">
                <input
                  type="text"
                  placeholder="검색..."
                  className="pl-10 pr-4 py-2 bg-gray-700 text-white border border-gray-600 rounded-full"
                />
                <Search
                  className="absolute left-3 top-2.5 text-gray-400"
                  size={18}
                />
              </div>
              <button className="bg-blue-600 text-white px-4 py-2 rounded-full flex items-center">
                <Plus size={18} className="mr-2" />
                새로 추가
              </button>
            </div>
          </div>
        </header> */}
        <main className="p-8">{renderContent()}</main>
      </div>
    </div>
  );
};

export default AdminDashboard;
