import React from 'react';

const SettingsPage = () => {
  return (
    <div className="space-y-6">
      <div className="bg-gray-800 p-6 rounded-lg">
        <h3 className="text-xl font-bold mb-4">일반 설정</h3>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">레스토랑 이름</label>
            <input type="text" className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white" placeholder="레스토랑 이름" />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">영업 시간</label>
            <div className="flex space-x-4">
              <input type="time" className="px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white" />
              <span className="text-gray-400 self-center">부터</span>
              <input type="time" className="px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white" />
              <span className="text-gray-400 self-center">까지</span>
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">통화</label>
            <select className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white">
              <option>KRW (₩)</option>
              <option>USD ($)</option>
              <option>EUR (€)</option>
            </select>
          </div>
        </div>
      </div>
      <div className="bg-gray-800 p-6 rounded-lg">
        <h3 className="text-xl font-bold mb-4">알림 설정</h3>
        <div className="space-y-4">
          <div className="flex items-center">
            <input type="checkbox" id="lowStock" className="mr-2" />
            <label htmlFor="lowStock" className="text-gray-300">재고 부족 알림</label>
          </div>
          <div className="flex items-center">
            <input type="checkbox" id="dailySales" className="mr-2" />
            <label htmlFor="dailySales" className="text-gray-300">일일 매출 보고서</label>
          </div>
          <div className="flex items-center">
            <input type="checkbox" id="newReservation" className="mr-2" />
            <label htmlFor="newReservation" className="text-gray-300">새 예약 알림</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;