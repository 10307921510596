import React, { useState, useEffect } from "react";
import {
  MapPin,
  Phone,
  CreditCard,
  FileText,
  Briefcase,
  ChevronRight,
  ChevronLeft,
  Check,
  X,
  AlertCircle,
  Loader,
  Image,
} from "lucide-react";
import axios from "axios";
import api from "../Services/api";

const steps = [
  { id: "basicInfo", title: "기본 정보", icon: MapPin },
  { id: "idCard", title: "신분증", icon: CreditCard },
  { id: "bankStatement", title: "통장 사본", icon: FileText },
  { id: "businessLicense", title: "사업자 등록증", icon: Briefcase },
];

const Tooltip = ({ children, content }) => (
  <div className="group relative">
    {children}
    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-1 bg-gray-700 text-white text-xs rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap">
      {content}
      <div className="absolute top-full left-1/2 transform -translate-x-1/2 border-4 border-transparent border-t-gray-700"></div>
    </div>
  </div>
);

export const StoreRegistrationForm = ({ onClose, onStoreAdded }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    contactNumber: "",
    idCardFile: null,
    bankStatementFile: null,
    businessLicenseFile: null,
  });
  const [previews, setPreviews] = useState({
    idCardFile: null,
    bankStatementFile: null,
    businessLicenseFile: null,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [animation, setAnimation] = useState("");

  useEffect(() => {
    setAnimation("fade-in");
    const timer = setTimeout(() => setAnimation(""), 500);
    return () => clearTimeout(timer);
  }, [currentStep]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviews((prevPreviews) => ({
          ...prevPreviews,
          [name]: reader.result,
        }));
      };
      reader.readAsDataURL(files[0]);
    }
  };
  const getStepSummary = (index) => {
    switch (index) {
      case 0:
        return formData.name
          ? `${formData.name.substring(0, 15)}${
              formData.name.length > 15 ? "..." : ""
            }`
          : "미입력";
      case 1:
        return formData.idCardFile ? "파일 선택됨" : "미선택";
      case 2:
        return formData.bankStatementFile ? "파일 선택됨" : "미선택";
      case 3:
        return formData.businessLicenseFile ? "파일 선택됨" : "미선택";
      default:
        return "";
    }
  };

  const validateStep = () => {
    switch (currentStep) {
      case 0:
        return formData.name && formData.address && formData.contactNumber;
      case 1:
        return formData.idCardFile;
      case 2:
        return formData.bankStatementFile;
      case 3:
        return formData.businessLicenseFile;
      default:
        return false;
    }
  };

  const handleNext = () => {
    if (validateStep()) {
      setAnimation("slide-out-left");
      setTimeout(() => {
        setCurrentStep((prevStep) => prevStep + 1);
        setAnimation("slide-in-right");
      }, 300);
    } else {
      setError("모든 필드를 채워주세요.");
    }
  };

  const handlePrevious = () => {
    setAnimation("slide-out-right");
    setTimeout(() => {
      setCurrentStep((prevStep) => prevStep - 1);
      setAnimation("slide-in-left");
    }, 300);
    setError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateStep()) {
      setError("모든 필드를 채워주세요.");
      return;
    }

    setIsLoading(true);
    setError(null);
    setSuccess(false);

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    try {
      const response = await api.post(
        `${process.env.REACT_APP_API_URL}/api/owner/pending/store`,
        formDataToSend,
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setSuccess(true);
        onStoreAdded(response.data);
        setTimeout(() => {
          onClose();
        }, 2000);
      }
    } catch (err) {
      setError(
        err.response?.data?.message || "업체 등록 중 오류가 발생했습니다."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-300 mb-2"
              >
                업체 이름
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="address"
                className="block text-sm font-medium text-gray-300 mb-2"
              >
                <MapPin size={16} className="inline mr-2" />
                업체 주소
              </label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="contactNumber"
                className="block text-sm font-medium text-gray-300 mb-2"
              >
                <Phone size={16} className="inline mr-2" />
                연락처 번호
              </label>
              <input
                type="tel"
                id="contactNumber"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </>
        );
      case 1:
        return (
          <div className="mb-4">
            <label
              htmlFor="idCardFile"
              className="block text-sm font-medium text-gray-300 mb-2"
            >
              <CreditCard size={16} className="inline mr-2" />
              신분증 파일
            </label>
            <input
              type="file"
              id="idCardFile"
              name="idCardFile"
              onChange={handleFileChange}
              accept="image/*"
              required
              className="hidden"
            />
            <div
              className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md cursor-pointer"
              onClick={() => document.getElementById("idCardFile").click()}
            >
              <div className="space-y-1 text-center">
                {previews.idCardFile ? (
                  <img
                    src={previews.idCardFile}
                    alt="ID Card Preview"
                    className="mx-auto h-32 w-auto"
                  />
                ) : (
                  <Image
                    className="mx-auto h-12 w-12 text-gray-400"
                    aria-hidden="true"
                  />
                )}
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor="idCardFile"
                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                  >
                    <span>파일 업로드</span>
                  </label>
                  <p className="pl-1">또는 드래그 앤 드롭</p>
                </div>
                <p className="text-xs text-gray-500">
                  PNG, JPG, GIF up to 10MB
                </p>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="mb-4">
            <label
              htmlFor="bankStatementFile"
              className="block text-sm font-medium text-gray-300 mb-2"
            >
              <FileText size={16} className="inline mr-2" />
              통장 사본 파일
            </label>
            <input
              type="file"
              id="bankStatementFile"
              name="bankStatementFile"
              onChange={handleFileChange}
              accept="image/*,.pdf"
              required
              className="hidden"
            />
            <div
              className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md cursor-pointer"
              onClick={() =>
                document.getElementById("bankStatementFile").click()
              }
            >
              <div className="space-y-1 text-center">
                {previews.bankStatementFile ? (
                  previews.bankStatementFile.startsWith("data:image") ? (
                    <img
                      src={previews.bankStatementFile}
                      alt="Bank Statement Preview"
                      className="mx-auto h-32 w-auto"
                    />
                  ) : (
                    <FileText
                      className="mx-auto h-12 w-12 text-gray-400"
                      aria-hidden="true"
                    />
                  )
                ) : (
                  <FileText
                    className="mx-auto h-12 w-12 text-gray-400"
                    aria-hidden="true"
                  />
                )}
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor="bankStatementFile"
                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                  >
                    <span>파일 업로드</span>
                  </label>
                  <p className="pl-1">또는 드래그 앤 드롭</p>
                </div>
                <p className="text-xs text-gray-500">
                  PNG, JPG, GIF, PDF up to 10MB
                </p>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="mb-4">
            <label
              htmlFor="businessLicenseFile"
              className="block text-sm font-medium text-gray-300 mb-2"
            >
              <Briefcase size={16} className="inline mr-2" />
              사업자 등록증 파일
            </label>
            <input
              type="file"
              id="businessLicenseFile"
              name="businessLicenseFile"
              onChange={handleFileChange}
              accept="image/*,.pdf"
              required
              className="hidden"
            />
            <div
              className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md cursor-pointer"
              onClick={() =>
                document.getElementById("businessLicenseFile").click()
              }
            >
              <div className="space-y-1 text-center">
                {previews.businessLicenseFile ? (
                  previews.businessLicenseFile.startsWith("data:image") ? (
                    <img
                      src={previews.businessLicenseFile}
                      alt="Business License Preview"
                      className="mx-auto h-32 w-auto"
                    />
                  ) : (
                    <FileText
                      className="mx-auto h-12 w-12 text-gray-400"
                      aria-hidden="true"
                    />
                  )
                ) : (
                  <Briefcase
                    className="mx-auto h-12 w-12 text-gray-400"
                    aria-hidden="true"
                  />
                )}
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor="businessLicenseFile"
                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                  >
                    <span>파일 업로드</span>
                  </label>
                  <p className="pl-1">또는 드래그 앤 드롭</p>
                </div>
                <p className="text-xs text-gray-500">
                  PNG, JPG, GIF, PDF up to 10MB
                </p>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full">

      <div className="flex justify-between items-center mb-6">
      {/* 왼쪽에 위치한 제목 */}
      <h2 className="text-2xl font-bold text-white">새 매장 등록</h2>
      
      {/* 오른쪽에 위치한 X 버튼 */}
      <button
        onClick={onClose}
        className="text-white hover:text-red-500 transition duration-300"
      >
        <X size={24} />
      </button>
    </div>

        {error && (
          <div className="mb-4 p-3 bg-red-500 bg-opacity-20 border border-red-500 rounded-md flex items-center">
            <AlertCircle size={20} className="text-red-500 mr-2" />
            <p className="text-red-500">{error}</p>
          </div>
        )}
        {success && (
          <div className="mb-4 p-3 bg-green-500 bg-opacity-20 border border-green-500 rounded-md flex items-center">
            <Check size={20} className="text-green-500 mr-2" />
            <p className="text-green-500">매장이 성공적으로 등록되었습니다.</p>
          </div>
        )}
        <div className="mb-8 relative">
          <div className="absolute top-[35%] left-4 w-[90%] h-1 bg-gray-600 transform -translate-y-1/2">
            <div
              className="h-full bg-blue-500 transition-all duration-300 ease-in-out"
              style={{ width: `${(currentStep / (steps.length - 1)) * 100}%` }}
            ></div>
          </div>
          <div className="flex justify-between items-center relative">
            {steps.map((step, index) => (
              <Tooltip key={step.id} content={getStepSummary(index)}>
                <div className="flex flex-col items-center z-10">
                  <div
                    className={`w-12 h-12 rounded-full flex items-center justify-center transition-colors duration-300 ${
                      index < currentStep
                        ? "bg-green-500"
                        : index === currentStep
                        ? "bg-blue-500"
                        : "bg-gray-600"
                    }`}
                  >
                    {index < currentStep ? (
                      <Check size={24} className="text-white" />
                    ) : (
                      <step.icon size={24} className="text-white" />
                    )}
                  </div>
                  <p className="text-xs mt-2 text-gray-400">{step.title}</p>
                </div>
              </Tooltip>
            ))}
          </div>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className={`transition-all duration-300 ${animation}`}>
            {renderStepContent()}
          </div>
          <div className="flex justify-between space-x-2 mt-6">
            {currentStep > 0 && (
              <button
                type="button"
                onClick={handlePrevious}
                className="px-4 py-2 bg-gray-600 text-white rounded-full hover:bg-gray-700 transition duration-300 flex items-center"
              >
                <ChevronLeft size={20} className="mr-1" />
                이전
              </button>
            )}
            {currentStep < steps.length - 1 ? (
              <button
                type="button"
                onClick={handleNext}
                className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition duration-300 flex items-center ml-auto"
              >
                다음
                <ChevronRight size={20} className="ml-1" />
              </button>
            ) : (
              <button
                type="submit"
                disabled={isLoading}
                className="px-4 py-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition duration-300 flex items-center ml-auto disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isLoading ? (
                  <>
                    <Loader size={20} className="mr-2 animate-spin" />
                    등록 중...
                  </>
                ) : (
                  <>
                    매장 등록
                    <Check size={20} className="ml-2" />
                  </>
                )}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
