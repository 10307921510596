import React from "react";

const CustomAlert = ({ message, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-sm w-full text-center">
        <p
          className="text-white mb-4"
          dangerouslySetInnerHTML={{ __html: message }}
        ></p>
        <button
          className="bg-emerald-500 hover:bg-emerald-600 text-white font-bold py-2 px-4 rounded focus:outline-none"
          onClick={onClose}
        >
          확인
        </button>
      </div>
    </div>
  );
};

export default CustomAlert;
