import React from 'react';
import { AlertCircle } from 'lucide-react';

export const DashboardCard = ({ title, value, change }) => (
  <div className="bg-gray-800 p-4 rounded-lg">
    <h3 className="text-lg font-semibold text-gray-300">{title}</h3>
    <p className="text-2xl font-bold mt-2">{value}</p>
    <p className={`text-sm ${change.startsWith('+') ? 'text-green-500' : 'text-red-500'}`}>
      {change}
    </p>
  </div>
);

export const AlertItem = ({ message }) => (
  <div className="flex items-center bg-yellow-900 border-l-4 border-yellow-500 text-yellow-200 p-4 rounded">
    <AlertCircle className="mr-2" size={18} />
    <p>{message}</p>
  </div>
);
